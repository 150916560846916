const Image1 = "/images/Blogs/perfect-home-1.jpg";
const Image2 = "/images/Blogs/perfect-home-2.jpg";

const data = {
    slug: "why-pre-launch-properties-are-tickets-to-your-perfect-home",
    image: Image1,
    imageAlt: "Why Pre-Launch Properties Are Ticket to Your Perfect Home?",
    title: "Why Pre-Launch Properties Are Ticket to Your Perfect Home?",
    time: "7 min read",
    publishedOn: "November,2024",
    title1: "Table of Contents",
    description11: (
        <ul className="table-of-content">
            <li>
                <a
                    href="#introduction"
                    onClick={() => {
                        window.scrollToElement("#introduction");
                    }}
                >
                    Introduction
                </a>
            </li>
            <li>
                <a
                    href="#why-should-you-buy-a-pre-launch-property"
                    onClick={() => {
                        window.scrollToElement(
                            "#why-should-you-buy-a-pre-launch-property"
                        );
                    }}
                >
                    Why should you buy a pre-launch property?
                </a>
                <ul>
                    <li>Customisation</li>
                    <li>Higher appreciation</li>
                    <li>Best Deals</li>
                    <li>Securing Property Before Price Hike</li>
                </ul>
            </li>
            <li>
                <a
                    href="#is-a-pre-launch-property-right-for-you"
                    onClick={() => {
                        window.scrollToElement(
                            "#is-a-pre-launch-property-right-for-you"
                        );
                    }}
                >
                    Is a Pre-Launch Property Right for You?
                </a>
                <ul>
                    <li>Spot Emerging Hotspots</li>
                    <li>Research Developer's Reputation</li>
                    <li>Legal Approvals</li>
                    <li>Risk Assessment</li>
                    <li>Decision-Making</li>
                </ul>
            </li>
            <li>
                <a
                    href="#disadvantages-of-investing-in-pre-launch-projects"
                    onClick={() => {
                        window.scrollToElement(
                            "#disadvantages-of-investing-in-pre-launch-projects"
                        );
                    }}
                >
                    Disadvantages of Investing in Pre-Launch Projects
                </a>
                <ul>
                    <li>Plan Modifications</li>
                    <li>Project Delays</li>
                    <li>Project Cancellation</li>
                </ul>
            </li>
            <li>
                <a
                    href="#summary"
                    onClick={() => {
                        window.scrollToElement("#summary");
                    }}
                >
                    Summary
                </a>
            </li>
            <li>
                <a
                    href="#essential-checklist-before-you-invest-in-pre-launch-property"
                    onClick={() => {
                        window.scrollToElement(
                            "#essential-checklist-before-you-invest-in-pre-launch-property"
                        );
                    }}
                >
                    Essential checklist before you invest in pre-launch property
                </a>
                <ul>
                    <li>Understand the Project</li>
                    <li>Gradual Investment</li>
                    <li>Document Inspection</li>
                    <li>Secure Payments</li>
                    <li>Location Analysis</li>
                    <li>Reputed Builder</li>
                    <li>Exit Strategy</li>
                </ul>
            </li>
        </ul>
    ),
    title2: (
        <div id="introduction" className="scroll-top">
            Introduction
        </div>
    ),
    description21:
        "Are you in search of a smart investment and your dream home? Pre-launch properties present a fantastic opportunity! These options are available before they officially hit the market, frequently at discounted prices. Imagine owning a home in a highly sought-after area, confident that you’ve made a savvy financial move by investing early. Whether you seek a future-proof investment or a dream home in a prime location, pre-launch properties could be your perfect solution.",
    title3: (
        <div
            id="why-should-you-buy-a-pre-launch-property"
            className="scroll-top"
        >
            What is a Pre-Launch Property?
        </div>
    ),
    description31: (
        <>
            <p>
                A pre-launch property refers to a building project before its
                official launch. Buying a property during this stage can be a
                savvy move for both buyers and investors. Developers often use
                pre-launch sales to raise funds before obtaining final
                approvals, avoiding the need for expensive loans.
            </p>
            <p>
                Pre-launch properties offer several benefits, including lower
                purchase costs, customisation options, the potential for high
                appreciation, and flexible payment plans. Buyers can take
                advantage of early bird discounts, exclusive unit selection, and
                the opportunity to witness their property's value grow.
            </p>
            <p>
                According to a
                <a
                    href="https://jll-global-gdim-res.cloudinary.com/raw/upload/v1717143504/2024-05/jll-q1-2024-residential-market-update.pdf"
                    className="external-link"
                    target="_blank"
                    rel="noreferrer"
                >
                    {" "}
                    JLL report
                </a>
                , 90% of Bangalore's property sales involve under-construction
                projects, including pre-launch offerings, reflecting strong
                buyer confidence. So, why are more people considering pre-launch
                properties? Let's explore the reasons.
            </p>
        </>
    ),

    title4: (
        <div
            id="why-should-you-buy-a-pre-launch-property"
            className="scroll-top"
        >
            What are the Benefits of Buying Pre-Launch Properties?
        </div>
    ),
    list41: [
        {
            label: "Lower Prices and Best Deals",
            description: (
                <>
                    Pre-launch properties are typically offered at prices 20-30%
                    lower than current market rates, as developers provide
                    attractive discounts during this phase to attract early
                    buyers and investors while securing funding for their
                    projects. Purchasing during the pre-launch stage allows
                    buyers to secure the best deals available, making it a
                    cost-effective opportunity for those looking to invest or
                    purchase a home within a budget.
                </>
            ),
        },
        {
            label: "Customisation",
            description:
                "When you buy a pre-launch property, you often get the chance to put your personal touch on your future home. You can choose everything from interior finishes and bathroom fixtures to kitchen designs and lighting. Customise storage, design your outdoor spaces or add smart home features and energy-efficient upgrades. These options let you create a home that really fits your style and needs. Just keep in mind that major changes like altering the layout or adding/removing walls might not be doable.",
        },
        {
            label: "Inventory",
            description:
                "Buying a property during its pre-launch phase gives you the advantage of selecting your preferred floor, unit, view, and direction. With more options available, you can choose a home that perfectly aligns with your lifestyle. Additionally, early selection often means you have access to the best units before they are taken by others.",
        },
        {
            label: "Higher appreciation",
            description: (
                <>
                    Investing in a pre-launch property can yield significant
                    returns. Prices tend to rise once the project is ready for
                    possession, allowing early buyers to benefit from price
                    appreciation. Whether planning to sell for a profit or to
                    allocate more funds to real estate, buying at this stage
                    offers a great opportunity for a strong return on
                    investment.
                    <br />
                    Bangalore continues to dominate the real estate market for
                    the second consecutive year, with a remarkable 124% increase
                    in sales above the average. The city's strong
                    infrastructure, thriving IT sector, and business-friendly
                    environment make it a magnet for investors and homebuyers
                    alike.
                    <br />
                    Sarjapur Road has become a popular real estate destination
                    in Bangalore due to its strategic location, excellent
                    connectivity, and growing amenities. The area's proximity to
                    IT hubs and educational institutions has driven a
                    significant increase in demand for residential properties.
                </>
            ),
        },
        {
            label: "Securing Property Before Price Hike",
            description:
                "Purchasing a property during the pre-launch phase allows buyers to lock in the price before it rises. Price hikes are common after projects receive RERA certification due to increased demand, infrastructure development, rising labour and material costs, and regulatory changes. Securing a property early can save a significant amount, which can be used for future expenses such as registration and interior design.",
        },
    ],
    title5: (
        <div id="is-a-pre-launch-property-right-for-you" className="scroll-top">
            How to find the best Pre-Launch property?
        </div>
    ),
    image5: Image2,
    image5alt: "How to find the best Pre-Launch property?",

    description51:
        "Success in such investments hinges on understanding market trends. By conducting thorough research, you can:",
    list52: [
        {
            label: "1. Spot Emerging Hotspots",
            description:
                "Identify areas or sectors poised for high growth due to factors like new infrastructure projects or favourable government policies. Focus on properties in these promising locations to maximise your return on investment. ",
            list: [
                "For example: The traditional South-East Bengaluru (Sarjapur Road, Kanakpura Road, Electronic City, Bannerghatta Road) micro-market continued to dominate sales comprising a share of 39% of the total sales in the city in 2023.",
            ],
        },
        {
            label: "2. Research Developer's Track Record",
            list: [
                "Look into the developer's history and track record. Check their portfolio of past projects. Look for reviews and testimonials from previous clients or buyers.",
                "Verify if they have consistently delivered high-quality projects on time. Look for any history of delays or issues.",
            ],
        },
        {
            label: "3. Legal Approvals",
            description: (
                <>
                    When investing in real estate, verifying that the developer
                    has secured all necessary legal approvals is essential.This
                    not only protects your investment but ensures that the
                    project complies with local laws and regulations,
                    safeguarding you from potential issues down the road.",
                    <p>Key legal approvals to check include:</p>
                </>
            ),
            list: [
                "BSNL : Clearance from Bharat Sanchar Nigam Limited (Telecommunications Department)",
                "NHAI : Approval from the National Highways Authority of India for road accessibility",
                "KIADB : Karnataka Industrial Area Development Board approvals for projects in industrial zones",
                "Fire Department : Clearance ensuring safety measures are in place",
                "KSPCB : Karnataka State Pollution Control Board approval for environmental compliance",
                "KHB : Karnataka Housing Board approval for housing projects",
                "BDA/BMRDA : Approvals from Bangalore Development Authority or Bangalore Metropolitan Region Development Authority for urban projects",
            ],
            descriptionFooter:
                "Additionally, ensure an NOC (No Objection Certificate) from the local authority is obtained. Reviewing these documents not only guarantees that the project adheres to local standards but also helps avoid legal complications in the future.",
        },
        {
            label: "4. Risk Assessment",
            description:
                "Combine the information gathered to assess the overall risk associated with investing in the pre-launch property. Weigh out factors such as market conditions, demand projections, and potential returns compared to the risks identified.",
        },
        {
            label: "5. Decision Making",
            description:
                "Based on the evaluations, make an informed decision on whether to invest in the pre-launch property. If possible get your decision evaluated by a real estate professional or any legal advisor.",
        },
    ],

    title6: (
        <div
            id="disadvantages-of-investing-in-pre-launch-projects"
            className="scroll-top"
        >
            What are the disadvantages of Investing in Pre-Launch Projects?
        </div>
    ),
    description61:
        "While investing in pre-launch projects can offer significant benefits like lower costs and access to prime units, it's important to understand that these advantages come with certain risks. Here are some potential drawbacks to consider.",
    list62: [
        {
            label: "Plan Modifications",
            description:
                "The initial plan presented during the pre-launch phase may undergo changes during the approval process. This means that the final development might differ from the original plan. These modifications can range from minor adjustments to significant alterations.",
        },
        {
            label: "Project Delays",
            description:
                "Obtaining approvals can be a lengthy process, involving multiple authorities. This can lead to indefinite delays in the project's timeline.",
        },
        {
            label: "Project Cancellation",
            description:
                "If a builder fails to obtain necessary approvals or secure sufficient funding, a project may be delayed or even cancelled. This can lead to financial losses for investors, as their initial investment could be at risk. Therefore, understanding local building and zoning regulations is crucial.",
        },
    ],
    title7: (
        <div id="summary" className="scroll-top">
            Summary
        </div>
    ),
    description72: (
        <>
            Buying a property during the pre-launch phase comes with several
            advantages. You get the chance to choose from various floor plans,
            make a smart investment, secure the best deals, and enjoy flexible
            payment plans. It’s no wonder pre-launch properties are a popular
            choice for savvy buyers and investors.
            <br />
            Of course, like anything, there are pros and cons. Before diving in,
            it’s essential to do your homework—check the project details,
            location, market trends, and the builder’s track record. Not
            everyone will be comfortable with the risks involved in pre-launch
            property investments. It's essential to conduct thorough due
            diligence on the builder's track record and ensure that all
            necessary approvals and funding are in place before proceeding. Your
            risk tolerance will play a crucial role in your decision-making
            process.
            <br />
            Curious about Modern Spaaces pre-launch properties? Call +91 900 800
            67 68 or visit
            <a
                href="http://www.modernspaaces.com/"
                className="external-link"
                target="_blank"
                rel="noreferrer"
            >
                {" "}
                www.modernspaaces.com{" "}
            </a>
            for more details!
        </>
    ),

    title8: (
        <div
            id="essential-checklist-before-you-invest-in-pre-launch-property"
            className="scroll-top"
        >
            Essential checklist before you invest in Pre-Launch property
        </div>
    ),
    list81: [
        {
            label: "Understand the Project",
            description:
                "Gain a comprehensive understanding of the project proposition and plan before investing.",
        },
        {
            label: "Gradual Investment",
            description:
                "Avoid investing large sums of money all at once. Gradual investment means making smaller, scheduled payments toward the property over time, rather than paying a large lump sum upfront.",
        },
        {
            label: "Document Inspection",
            description:
                "Check all legal approvals, especially those related to land, and if possible get them inspected by a legal professional.",
        },
        {
            label: "Secure Payments",
            description:
                "Always make payments via cheque, demand draft, or online transfer. Avoid any cash transactions.",
        },
        {
            label: "Location Analysis",
            description:
                "Carefully evaluate the location and the feasibility of the project in that area.",
        },
        {
            label: "Reputed Builder",
            description:
                "Without any second thought it is safe to say that always invest in a property that is developed by trustworthy builders.",
        },
        {
            label: "Exit Strategy",
            description:
                "It refers to your plan for selling or renting out your property in the future. It's an important consideration when investing in pre-launch properties.",
        },
    ],

    Faq: (
        <div id="faqs" className="scroll-top">
            FAQs (Frequently Asked Questions)
        </div>
    ),
    faqs: [
        {
            label: "Q1: What is a Pre-Launch property?",
            description:
                "Pre-launch property is a real estate project that is announced before its official market launch. Developers often offer these properties at discounted prices to attract early buyers and secure funding before obtaining all the necessary approvals.",
        },
        {
            label: "Q2: What are the benefits of buying a Pre-Launch property?",
            description:
                "Buying a pre-launch property can provide several benefits, such as lower prices, the ability to customise the property, the potential for higher appreciation, securing the best deals, and enjoying flexible payment options.",
        },
        {
            label: "Q3: How can I ensure the safety of my investment in a Pre-Launch property?",
            description:
                "To safeguard your investment, make sure to research the developer’s reputation, verify all legal approvals, assess the market trends, and consult with a real estate professional. Additionally, ensure that all transactions are documented and legally verified.",
        },
        {
            label: "Q4: Does Modern Spaaces have any Pre-Launch property in the pipeline?",
            description:
                "Yes, we have an exciting portfolio of upcoming projects in the pipeline, covering a massive 15 million square feet.",
        },
    ],
};

export default data;
