import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Desc, DescLabel } from "../../../Global/Typography";
import BannerNew from "../../BannerNew";
import { Helmet } from "react-helmet-async";
import "../../BlogBenefitsOfExclusiveCommunity/Banner/index.scss";
import { scrollIntoView } from "seamless-scroll-polyfill";

import { Accordion, Card, Button } from "react-bootstrap";
import BlogData from "../../../../db/BlogBenefitsOfExclusiveCommunity";
import ShareButton from "../../Share/ShareButton";

window.scrollToElement = function (id) {
    const el = document.querySelector(id);
    if (el) {
        scrollIntoView(el, {
            behavior: "smooth",
            top: 200,
        });
    }
};

export default function Banner({ inView }) {
    const [activeKey, setActiveKey] = useState(null);

    const toggleAccordion = (key) => {
        setActiveKey(activeKey === key ? null : key);
    };

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1.0"
                />
                <title>
                    Benefits of living in an exclusive 3 BHK community
                </title>
                <meta
                    name="Keywords"
                    content="" //TODO
                />
                <link
                    rel="canonical"
                    href="https://modernspaaces.com/blogs/benefits-of-living-in-an-exclusive-3-BHK-community"
                />
            </Helmet>
            <section className={`section  ${inView ? "inView" : "outView"}`}>
                <BannerNew
                    image={BlogData.image}
                    title={BlogData.title}
                    publishedOn={BlogData.publishedOn}
                    time={BlogData.time}
                />

                <Container className="blog-detail-container">
                    <Row className="justify-content-around">
                        <Col xs={12} md={11} lg={9} className="pb-4 pr-md-5">
                            <div className="blog-header margin">
                                {BlogData.title110 && (
                                    <p className="-descBlack font-benton-bold">
                                        {BlogData.title110}
                                    </p>
                                )}
                                {BlogData.description110 && (
                                    <Desc
                                        classList="font-benton-bold header"
                                        text={BlogData.description110}
                                    />
                                )}
                                {BlogData.description111 && (
                                    <Desc
                                        classList="font-benton-book header"
                                        text={BlogData.description111}
                                    />
                                )}
                                {BlogData.description112 && (
                                    <Desc
                                        classList="font-benton-book header"
                                        text={BlogData.description112}
                                    />
                                )}
                                {BlogData.title2 && (
                                    <p className="-descBlack font-benton-bold margin mb-3">
                                        {BlogData.title2}
                                    </p>
                                )}

                                {BlogData.title2list &&
                                    BlogData.title2list.map((i0, idx) => {
                                        return (
                                            <div key={idx}>
                                                <DescLabel
                                                    classList="d-flex flex-column my-1 py-2 font-benton-book header"
                                                    text={i0.description}
                                                    label={i0.label}
                                                    showColon={false}
                                                />
                                            </div>
                                        );
                                    })}
                                {BlogData.title3 && (
                                    <p className="-descBlack font-benton-bold  margin">
                                        {BlogData.title3}
                                    </p>
                                )}
                                {BlogData.description3 && (
                                    <Desc
                                        classList="font-benton-book header"
                                        text={BlogData.description3}
                                    />
                                )}
                                {BlogData.title4 && (
                                    <p className="-descBlack font-benton-bold  margin">
                                        {BlogData.title4}
                                    </p>
                                )}
                                {BlogData.description4 && (
                                    <Desc
                                        classList="font-benton-book header"
                                        text={BlogData.description4}
                                    />
                                )}
                                {BlogData.title5 && (
                                    <p className="-descBlack font-benton-bold  margin">
                                        {BlogData.title5}
                                    </p>
                                )}
                                {BlogData.description5 && (
                                    <Desc
                                        classList="font-benton-book header"
                                        text={BlogData.description5}
                                    />
                                )}
                                {BlogData.title6 && (
                                    <p className="-descBlack font-benton-bold  margin">
                                        {BlogData.title6}
                                    </p>
                                )}
                                {BlogData.description6 && (
                                    <Desc
                                        classList="font-benton-book header"
                                        text={BlogData.description6}
                                    />
                                )}
                                {BlogData.title7 && (
                                    <p className="-descBlack font-benton-bold  margin">
                                        {BlogData.title7}
                                    </p>
                                )}
                                {BlogData.description7 && (
                                    <Desc
                                        classList="font-benton-book header"
                                        text={BlogData.description7}
                                    />
                                )}
                                {BlogData.title8 && (
                                    <p className="-descBlack font-benton-bold  margin">
                                        {BlogData.title8}
                                    </p>
                                )}
                                {BlogData.description8 && (
                                    <Desc
                                        classList="font-benton-book header"
                                        text={BlogData.description8}
                                    />
                                )}
                                {BlogData.title9 && (
                                    <p className="-descBlack font-benton-bold margin">
                                        {BlogData.title9}
                                    </p>
                                )}
                                {BlogData.description9 && (
                                    <Desc
                                        classList="font-benton-book header"
                                        text={BlogData.description9}
                                    />
                                )}
                                {BlogData.title10 && (
                                    <p className="-descBlack font-benton-bold margin">
                                        {BlogData.title10}
                                    </p>
                                )}
                                <div className="pros-cons">
                                    <div className="table-col">
                                        <div
                                            className="pros font-benton-bold text-center p-2"
                                            style={{
                                                fontSize: "18px",
                                            }}
                                        >
                                            Pros
                                        </div>
                                        <div
                                            className="p-4"
                                            style={{ fontSize: "14px" }}
                                        >
                                            {BlogData.pros.map((item) => (
                                                <>
                                                    <div className="font-benton-bold">
                                                        {item.heading}
                                                    </div>
                                                    <div
                                                        style={{
                                                            paddingBottom:
                                                                "24px",
                                                        }}
                                                    >
                                                        {item.description}
                                                    </div>
                                                </>
                                            ))}
                                        </div>
                                    </div>
                                    <div className="table-col">
                                        <div
                                            className="cons font-benton-bold text-center p-2"
                                            style={{
                                                fontSize: "18px",
                                            }}
                                        >
                                            Cons
                                        </div>
                                        <div
                                            className="p-4"
                                            style={{ fontSize: "14px" }}
                                        >
                                            {BlogData.cons.map((item) => (
                                                <>
                                                    <div className="font-benton-bold">
                                                        {item.heading}
                                                    </div>
                                                    <div
                                                        style={{
                                                            paddingBottom:
                                                                "24px",
                                                        }}
                                                    >
                                                        {item.description}
                                                    </div>
                                                </>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                                {BlogData.description10 && (
                                    <Desc
                                        classList="font-benton-book header"
                                        text={BlogData.description10}
                                    />
                                )}
                                {BlogData.title11 && (
                                    <p className="-descBlack font-benton-bold  margin">
                                        {BlogData.title11}
                                    </p>
                                )}
                                {BlogData.description11 && (
                                    <Desc
                                        classList="font-benton-book header"
                                        text={BlogData.description11}
                                    />
                                )}
                            </div>

                            {/* <div className="blog-content ">
                                {item.title3 && (
                                    <p className="-descBlack font-benton-book fw-bold  margin">
                                        {item.title3}
                                    </p>
                                )}

                                {item.title3list &&
                                    item.title3list.map((i0, idx) => {
                                        return (
                                            <div key={idx}>
                                                <DescLabel
                                                    classList="my-1 py-2 font-benton-book header"
                                                    text={i0.description}
                                                    label={i0.label}
                                                />
                                                {i0.list && (
                                                    <ul className="blog-list-circle header">
                                                        {i0.list.map(
                                                            (i1, idx1) => {
                                                                return (
                                                                    <li
                                                                        key={
                                                                            idx1
                                                                        }
                                                                    >
                                                                        <p className="-descBlack mb-1 font-benton-book header">
                                                                            {i1}
                                                                        </p>
                                                                    </li>
                                                                );
                                                            }
                                                        )}
                                                    </ul>
                                                )}
                                            </div>
                                        );
                                    })}
                            </div> */}

                            {/* <div className="blog-content ">
                                {item.title4 && (
                                    <p className="-descBlack font-benton-book fw-bold margin">
                                        {item.title4}
                                    </p>
                                )}
                                {item.description41 && (
                                    <p className="-descBlack font-benton-book header">
                                        {item.description41}
                                    </p>
                                )}
                                {item.description42 && (
                                    <p className="-descBlack font-benton-book header">
                                        {item.description42}
                                    </p>
                                )}
                                {item.description43 && (
                                    <p className="-descBlack font-benton-book header">
                                        {item.description43}
                                    </p>
                                )}
                                {item.description44 && (
                                    <p className="-descBlack font-benton-book header">
                                        {item.description44}
                                    </p>
                                )}
                            </div> */}

                            {/* <div className="blog-content ">
                                {item.title5 && (
                                    <p className="-descBlack font-benton-book fw-bold margin">
                                        {item.title5}
                                    </p>
                                )}
                                {item.Conclusion && (
                                    <p className="-descBlack font-benton-book header">
                                        {item.Conclusion}
                                    </p>
                                )}
                            </div> */}

                            <section className="shareLinkBlock -only-mobile ">
                                <ShareButton shareUrl="https://modernspaaces.com/blogs/defference-between-a-khata-b-khata-and-its-impact-on-property" />
                            </section>
                        </Col>
                        <Col
                            xs={12}
                            md={4}
                            lg={4}
                            className="my-0 py-0 -aside-block"
                        >
                            {/* <Fade>
                  <div className="-blog-author">
                    <p className="--label">Written By</p>
                    <img
                      src={item.authorImage}
                      alt={item.authorName}
                      className="-authorImage"
                    />

                    <h2 className="my-3 -authorName">{item.authorName}</h2>
                    <h3 className="my-1 -department">{item.department}</h3>

                    <AnchorGradientLinkAhref
                      href={`tel:${item.contactNumber}`}
                      classList="-btn-link mt-4 d-block"
                      text="Ask expert"
                      // text={"Read More"}
                      target="_blank"
                    />
                  </div>
                              </Fade> */}
                        </Col>
                    </Row>
                    <Row className="simpleShadow my-3 py-3 justify-content-around">
                        <Col
                            xs={12}
                            md={11}
                            lg={9}
                            className="pb-4 pr-md-5 p-2"
                        >
                            <Row>
                                <div
                                    className="blog-content px-3"
                                    style={{
                                        width: "-webkit-fill-available",
                                    }}
                                >
                                    {BlogData.Faq && (
                                        <p className="-descBlack font-benton-bold  margin header2">
                                            {BlogData.Faq}
                                        </p>
                                    )}
                                    {BlogData.faqs &&
                                        BlogData.faqs.map((faq, faqIdx) => (
                                            <Accordion
                                                key={faqIdx}
                                                defaultActiveKey={faqIdx.toString()}
                                                activeKey={activeKey}
                                            >
                                                <Card
                                                    key={faqIdx}
                                                    className="my-2 p-2 font-benton-bold"
                                                    style={{
                                                        width: "fit",
                                                        border: "none",
                                                        backgroundColor:
                                                            "rgba(0, 0, 0, 0.02)",
                                                        borderRadius: "0.5rem",
                                                    }}
                                                >
                                                    <Accordion.Toggle
                                                        as={Card.Header}
                                                        eventKey={`${faqIdx}`}
                                                        className="font-benton-bold faq"
                                                        style={{
                                                            border: "none",
                                                            background: "none",
                                                        }}
                                                        onClick={() => toggleAccordion(faqIdx.toString())}
                                                    >
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                alignItems:
                                                                    "center",
                                                                justifyContent:
                                                                    "space-between",
                                                                cursor: "pointer",
                                                                borderBottom:
                                                                    "none",
                                                            }}
                                                        >
                                                            {faq.label}
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width="20"
                                                                height="21"
                                                                viewBox="0 0 20 21"
                                                                fill="none"
                                                                style={{
                                                                    transform: activeKey === faqIdx.toString() ? "rotate(180deg)" : "rotate(0deg)",
                                                                }}
                                                            >
                                                                <path
                                                                    d="M16.5984 7.96094L11.1651 13.3943C10.5234 14.0359 9.47344 14.0359 8.83177 13.3943L3.39844 7.96094"
                                                                    stroke="black"
                                                                    strokeOpacity="0.32"
                                                                    strokeWidth="2"
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                />
                                                            </svg>
                                                        </div>
                                                    </Accordion.Toggle>
                                                    <Accordion.Collapse
                                                        eventKey={`${faqIdx}`}
                                                    >
                                                        <Card.Body className="font-benton-book header p-3">
                                                            {faq.description[0]}
                                                        </Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                            </Accordion>
                                        ))}
                                </div>
                            </Row>
                        </Col>
                    </Row>
                    <Row className="simpleShadow my-3 py-3 justify-content-around">
                        <Col
                            xs={12}
                            md={11}
                            lg={9}
                            className="pb-4 pr-md-5 p-2"
                        >
                            <div
                                className="shareLinkBlock1 d-flex flex-row p-4 "
                                style={{
                                    backgroundColor: "rgba(0, 0, 0, 0.04)",
                                    gap: "1rem",
                                    borderRadius: "1rem",
                                }}
                            >
                                <ShareButton shareUrl="https://modernspaaces.com/blogs/defference-between-a-khata-b-khata-and-its-impact-on-property" />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
}
