import React, { useState } from "react";
import { InView } from "react-intersection-observer";
import { Route, Switch, useLocation } from "react-router-dom";
import BlogFour from "../components/BlogDetail/BlogFour";
import BlogOne from "../components/BlogDetail/BlogOne";
import BlogThree from "../components/BlogDetail/BlogThree";
import BlogTwo from "../components/BlogDetail/BlogTwo";

import BlogMivanTechnology from "../components/BlogDetail/BlogMivanTechnology";

import OtherBlogs from "../components/BlogDetail/OtherBlogs/OtherBlogs";
import { AllBlogs } from "../components/BlogList/AllResources/constants";
import { ContactUs } from "../components/SoulTree";
import { Footer, FooterLogo } from "../components/index";

import BlogReraInfluence from "../components/BlogDetail/BlogReraInfluence";
import BlogSimpleGuide from "../components/BlogDetail/BlogSimpleGuide";
import BlogKhata from "../components/BlogDetail/BlogA&Bkhata";
import BlogBenefitsOfExclusiveCommunity from "../components/BlogDetail/BlogBenefitsOfExclusiveCommunity";
import BlogA from "../components/BlogDetail/BlogA&Bkhata";

import BlogComparingCost from "../components/BlogDetail/BlogComparingCost";
import BlogChoosenGreen from "../components/BlogDetail/BlogChoosenGreen";
import BlogCertificate from "../components/BlogDetail/BlogCertificate";
import BlogPrimeAdvantage from "../components/BlogDetail/BlogPrimeAdvantage";
import BlogFactors from "../components/BlogDetail/BlogFactors";
import BlogEvalOfhomes from "../components/BlogDetail/BlogEvalofSmarthomes";
import BlogBudget2024 from "../components/BlogDetail/BlogBudget2024";
import BlogReadyMadeOrCustomVilla from "../components/BlogDetail/BlogReadyMadeOrCustomVilla";
import BlogBuyingApartmentVsBuildingHouse from "../components/BlogDetail/BlogBuyingApartmentVsBuildingHouse";
import BlogEngraceVistaTrue3BHK from "../components/BlogDetail/BlogEngraceVistaTrue3BHK";
import BlogPerfectHome from "../components/BlogDetail/BlogPerfectHome";
import BlogPetOwnership from "../components/BlogDetail/BlogPetOwnership";

const BlogParent = (children) => {
    const [inViewFooter, setInViewFooter] = useState(false);
    const [inViewContactUs, setInViewContactUs] = useState(false);
    const [inViewOtherBlogs, setInViewOtherBlogs] = useState(false);
    // const [inViewRelatedArticles, setInViewRelatedArticles] = useState(false);

    const location = useLocation();

    const blogId = location.pathname.split("/")[2];
    const totalBlogs = AllBlogs.length;
    const currentBlogIndex = AllBlogs.findIndex((blog) => blog.slug === blogId);
    const otherBlogs = [];
    otherBlogs.push(AllBlogs[(currentBlogIndex + 1) % totalBlogs]);
    otherBlogs.push(AllBlogs[(currentBlogIndex + 2) % totalBlogs]);
    otherBlogs.push(AllBlogs[(currentBlogIndex + 3) % totalBlogs]);
    // const otherBlogs = AllBlogs.slice(
    //     (currentBlogIndex + 1) % AllBlogs.length,
    //     (currentBlogIndex + 4) % AllBlogs.length
    // );

    return (
        <>
            <Switch>
                <Route
                    path={`/blogs/list-of-amenities-to-consider-buying-home`}
                    component={BlogOne}
                ></Route>
                <Route
                    path={`/blogs/things-to-consider-buying-your-first-home`}
                    component={BlogTwo}
                />
                <Route
                    exact
                    path={`/blogs/buying-your-house-in-neighborhood`}
                    component={BlogThree}
                />
                <Route
                    exact
                    path={`/blogs/why-sarjapura-best-area-to-buy-house`}
                    component={BlogFour}
                />
                <Route
                    exact
                    path={`/blogs/exploring-the-mivan-technology`}
                    component={BlogMivanTechnology}
                />
                <Route
                    exact
                    path={`/blogs/simple-guide-to-understanding-property-tax`}
                    component={BlogSimpleGuide}
                />
                <Route
                    exact
                    path={`/blogs/benefits-of-living-in-an-exclusive-3-BHK-community`}
                    component={BlogBenefitsOfExclusiveCommunity}
                />
                {/* <Route
          exact
          path={`/blogs/buying-your-house-in-neighborhood`}
          component={BlogThree}
        />
        <Route
          exact
          path={`/blogs/why-sarjapura-best-area-to-buy-house`}
          component={BlogFour}
        /> */}
                {/* <Route
          exact
          path={`/blogs/revolutionizing-construction`}
          component={BlogFive}
        /> */}
                <Route
                    exact
                    path={`/blogs/rera-how-it-benefits-homeowners-and-developers`}
                    component={BlogReraInfluence}
                />
                <Route
                    exact
                    path={`/blogs/defference-between-a-khata-b-khata-and-its-impact-on-property`}
                    component={BlogKhata}
                />
                <Route
                    exact
                    path={`/blogs/the-evolution-of-smart-homes`}
                    component={BlogEvalOfhomes}
                />
                <Route
                    exact
                    path={`/blogs/comparing-the-cost-building-vs-buying-homes`}
                    component={BlogComparingCost}
                />
                <Route
                    exact
                    path={`/blogs/choose-between-green-traditional-energy-efficient-buildings`}
                    component={BlogChoosenGreen}
                />
                <Route
                    exact
                    path={`/blogs/certificate-of-occupancy-simple-guide-to-what-it-means`}
                    component={BlogCertificate}
                />
                <Route
                    exact
                    path={`/blogs/advantage-of-villas-exploring-luxury-living`}
                    component={BlogPrimeAdvantage}
                />
                <Route
                    exact
                    path={`/blogs/factors-impacting-real-estate-location`}
                    component={BlogFactors}
                />
                <Route
                    exact
                    path="/blogs/budget-2024"
                    component={BlogBudget2024}
                />
                <Route
                    exact
                    path="/blogs/ready-made-villa-vs-custom-build"
                    component={BlogReadyMadeOrCustomVilla}
                />
                <Route
                    exact
                    path="/blogs/buying-apartment-vs-building-house"
                    component={BlogBuyingApartmentVsBuildingHouse}
                />
                <Route
                    exact
                    path="/blogs/why-is-engrace-vista-the-true-3-bhk"
                    component={BlogEngraceVistaTrue3BHK}
                />
                <Route
                    exact
                    path="/blogs/why-pre-launch-properties-are-tickets-to-your-perfect-home"
                    component={BlogPerfectHome}
                />
                <Route
                    exact
                    path="/blogs/a-comprehensive-guide-for-pet-ownership-in-bangalore"
                    component={BlogPetOwnership}
                />
            </Switch>

            {/* <InView
        className="bgPattern"
        onChange={setInViewRelatedArticles}
        triggerOnce={true}
      >
        <RelatedArticles inView={inViewRelatedArticles}></RelatedArticles>
      </InView> */}

            <InView
                className="bgPattern"
                onChange={setInViewOtherBlogs}
                triggerOnce={true}
            >
                <OtherBlogs
                    inView={inViewOtherBlogs}
                    blogs={otherBlogs}
                ></OtherBlogs>
            </InView>

            <InView
                className="bgPattern  bgPattern--HrGrassPattern"
                onChange={setInViewContactUs}
                triggerOnce={true}
            >
                <ContactUs inView={inViewContactUs}></ContactUs>
            </InView>
            <FooterLogo />
            <InView
                className="bgPattern "
                onChange={setInViewFooter}
                triggerOnce={true}
            >
                <Footer inView={inViewFooter}></Footer>
            </InView>
        </>
    );
};

export default BlogParent;
