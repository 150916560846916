const newTdsRule = "/images/Blogs/new-tds-rule.png";
const budget2024 = "/images/Blogs/budget-2024.jpg";

const data = {
    slug: "budget-2024",
    image: budget2024,
    imageAlt: "Budget 2024 - Your Wallet's New BFF or Your Worst Enemy",
    title: (
        <div className="d-flex flex-column">
            <span>Budget 2024</span>
            <span className="mt-n2">
                Your Wallet's New BFF or Your Worst Enemy
            </span>
        </div>
    ),
    time: "7 min read",
    publishedOn: "September,2024",
    title1: "Table of Contents",
    description11: (
        <ul className="table-of-content">
            <li>
                <a
                    href="#introduction"
                    onClick={() => {
                        window.scrollToElement("#introduction");
                    }}
                >
                    Introduction
                </a>
            </li>
            <li>
                <a
                    href="#budget-2024-real-estate"
                    onClick={() => {
                        window.scrollToElement("#budget-2024-real-estate");
                    }}
                >
                    Budget 2024 & Real Estate
                </a>
            </li>
            <li>
                <a
                    href="#ltcg-tax-update"
                    onClick={() => {
                        window.scrollToElement("#ltcg-tax-update");
                    }}
                >
                    Long-Term Capital Gains (LTCG) Tax Update
                </a>
            </li>
            <li>
                <a
                    href="#long-term-assets-section-112"
                    onClick={() => {
                        window.scrollToElement("#long-term-assets-section-112");
                    }}
                >
                    Long-Term Assets under Section 112
                </a>
            </li>
            <li>
                <a
                    href="#initial-change-indexation"
                    onClick={() => {
                        window.scrollToElement("#initial-change-indexation");
                    }}
                >
                    The Initial Change in Indexation
                </a>
            </li>
            <li>
                <a
                    href="#public-outcry-response"
                    onClick={() => {
                        window.scrollToElement("#public-outcry-response");
                    }}
                >
                    Public Outcry and Government Response
                </a>
            </li>
            <li>
                <a
                    href="#what-is-indexation"
                    onClick={() => {
                        window.scrollToElement("#what-is-indexation");
                    }}
                >
                    What Exactly is Indexation?
                </a>
            </li>
            <li>
                <a
                    href="#impact-removing-indexation"
                    onClick={() => {
                        window.scrollToElement("#impact-removing-indexation");
                    }}
                >
                    Impact of Removing Indexation on Property Gains
                </a>
            </li>
            <li>
                <a
                    href="#focus-on-women"
                    onClick={() => {
                        window.scrollToElement("#focus-on-women");
                    }}
                >
                    Focus on Women
                </a>
            </li>
            <li>
                <a
                    href="#new-tds-rule"
                    onClick={() => {
                        window.scrollToElement("#new-tds-rule");
                    }}
                >
                    New TDS Rule for Property Sales
                </a>
            </li>
            <li>
                <a
                    href="#market-outlook"
                    onClick={() => {
                        window.scrollToElement("#market-outlook");
                    }}
                >
                    Market Outlook
                </a>
            </li>
            <li>
                <a
                    href="#pmay-boost"
                    onClick={() => {
                        window.scrollToElement("#pmay-boost");
                    }}
                >
                    PMAY (Pradhan Mantri Awas Yojana) Gets a Boost
                </a>
            </li>
            <li>
                <a
                    href="#what-is-pmay"
                    onClick={() => {
                        window.scrollToElement("#what-is-pmay");
                    }}
                >
                    What is PMAY?
                </a>
            </li>
            <li>
                <a
                    href="#how-does-pmay-work"
                    onClick={() => {
                        window.scrollToElement("#how-does-pmay-work");
                    }}
                >
                    How does this work?
                </a>
            </li>
            <li>
                <a
                    href="#key-takeaway"
                    onClick={() => {
                        window.scrollToElement("#key-takeaway");
                    }}
                >
                    Key Takeaway from Budget 2024
                </a>
            </li>
            <li>
                <a
                    href="#summary"
                    onClick={() => {
                        window.scrollToElement("#summary");
                    }}
                >
                    Summary
                </a>
            </li>
        </ul>
    ),
    title2: (
        <div id="introduction" className="scroll-top">
            Introduction
        </div>
    ),
    description21:
        "The Union Budget 2024 has just rolled out some big changes for India's real estate sector. With a fresh focus on affordable housing, urban growth, and tax reforms, the government aims to stir up growth and attract investment. In this blog we’ll break down the major highlights so let’s dive into what these changes mean for property buyers, sellers, and investors, and how they might shape the future of India’s real estate market.",
    title3: (
        <div id="budget-2024-real-estate" className="scroll-top">
            Budget 2024 & Real Estate
        </div>
    ),
    description31:
        "The Union Budget 2024 has introduced significant updates, particularly impacting the real estate sector. Let's find out what these changes mean for the industry.",

    title4: (
        <div id="ltcg-tax-update" className="scroll-top">
            Long-Term Capital Gains (LTCG) Tax Update
        </div>
    ),
    list41: [
        {
            label: "New Tax Rate",
            description:
                "The LTCG tax on property sales has been reduced from 20% to 12.5%",
        },
        {
            label: "Holding Periods",
            list: [
                {
                    label: "Listed Securities",
                    description:
                        "Assets held for more than 12 months are considered Long-Term.",
                },
                {
                    label: "Other Assets",
                    description:
                        "Assets must be held for more than 24 months to qualify as Long-Term.",
                },
            ],
        },
        {
            label: "Previous Holding Periods",
            description:
                "The former 36-month holding period has been eliminated.",
        },
    ],
    title5: (
        <div id="long-term-assets-section-112" className="scroll-top">
            Long-Term Assets under Section 112
        </div>
    ),
    list51: [
        "Listed Securities",
        "Zero-Coupon Bonds",
        "Unlisted Securities",
        "Immovable Property",
        "Other Long-Term Capital Assets",
    ],
    title6: (
        <div id="initial-change-indexation" className="scroll-top">
            The Initial Change in Indexation
        </div>
    ),
    description61:
        "The Indian government initially proposed removing the indexation benefit for long-term capital gains (LTCG) on property sales. This meant that property owners would have to pay tax on the full profit, without adjusting for inflation.",
    title7: (
        <div id="public-outcry-response" className="scroll-top">
            Public Outcry and Government Response
        </div>
    ),
    description71:
        "Due to widespread public opposition, the government has introduced a new option for taxpayers:",
    list72: [
        {
            label: "Option 1:",
            description:
                "Pay a 20% tax on capital gains with indexation benefits (adjusting for inflation).",
        },
        {
            label: "Option 2:",
            description:
                "Pay a 12.5% tax on capital gains without indexation benefits.",
        },
    ],
    title73: "Key Points",
    list74: [
        {
            label: "TaxPayer Choice:",
            description:
                "Individuals can now select the tax option that benefits them most.",
        },
        {
            label: "Impact:",
            description:
                "The choice depends on the property's appreciation rate and the taxpayer's financial situation.",
        },
        {
            label: "Eligibility:",
            description:
                "This new tax regime applies only to properties acquired before July 23, 2024.",
        },
    ],
    description75:
        "This change provides more flexibility for property owners when calculating capital gains tax, with or without factoring Indexation benefit.",
    title8: (
        <div id="what-is-indexation" className="scroll-top">
            What Exactly is Indexation?
        </div>
    ),
    description81:
        "Indexation is a method used to adjust the purchase price of an asset, like stocks, bonds, or real estate, to account for inflation. Over time, the purchasing power of money decreases due to inflation. Indexation helps offset this decline by increasing the asset's original cost based on inflation rates, reducing the taxable capital gain when you sell the asset. In essence, indexation helps protect your investment returns from the eroding effects of inflation and keeps its value over time.",
    description82: {
        label: "Example of Indexation",
        description:
            "Indexation adjusts your property's purchase price for inflation, which lowers your taxable gain when you sell. For example, if you bought a property for ₹10 lakh in 2010 and sold it for ₹30 lakh in 2024, you might think you made a ₹20 lakh profit. But prices of everything go up over time (inflation). Indexation helps adjust the original price of your house to match these increased prices, so you might end up paying tax on a smaller profit instead of the full ₹20 lakh.",
    },
    title9: (
        <div id="impact-removing-indexation" className="scroll-top">
            Impact of Removing Indexation on Property Gains
        </div>
    ),
    list91: [
        {
            label: "No Impact for Reinvestors:",
            description:
                "If you sell your home and purchase another within two years, you can offset your capital gains and avoid additional taxes. This means you won’t have to pay capital gains tax on the profit from selling your old home.",
        },
        {
            label: "Increased Tax for Other Investors:",
            description:
                "People who invest their money in things other than property or don't reinvest it at all will likely see higher taxes because the inflation adjustment is being removed.",
        },
        {
            label: "Short-Term Investments Hit Harder:",
            description:
                "Properties held for a short period with modest price growth will be particularly affected by the new tax regime.",
        },
        {
            label: "Long-Term Investments Less Impacted:",
            description:
                "For properties held over ten years with significant price appreciation, the impact might be neutral or even slightly beneficial due to the lower overall tax rate.",
        },
    ],
    title10: (
        <div id="focus-on-women" className="scroll-top">
            Focus on Women
        </div>
    ),
    description101:
        "Finance Minister Nirmala Sitharaman has called on state governments to reduce stamp duty charges, especially for properties purchased by women. She emphasized that this reform will become a crucial element of urban development schemes.",
    description102:
        "“We will urge states with high stamp duty rates to moderate them for everyone and further reduce duties for properties bought by women. This reform will be integrated as a key part of urban development initiatives,” said Sitharaman.",
    title11: (
        <div id="new-tds-rule" className="scroll-top">
            New TDS Rule for Property Sales
        </div>
    ),
    image111: newTdsRule,
    image111alt: "New TDS Rule for Property Sales",
    description112:
        "The government has introduced a new rule regarding Tax Deducted at Source (TDS) under section 194-IA of the IT Act for property sales. If the total value of a property being sold is ₹50 lakh or more, a TDS of 1% must be deducted. This applies even if the property is being purchased by multiple individuals and each individual's share is less than ₹50 lakh. The purpose of this change is to ensure that the correct amount of TDS is collected on property transactions to prevent tax evasion.",
    description113:
        "For example, if two people buy a property worth ₹80 lakh together, each paying ₹40 lakh, both buyers must deduct TDS at 1% on their respective payments of ₹40 lakh. This is because the total property value exceeds ₹50 lakh.",
    title12: (
        <div id="market-outlook" className="scroll-top">
            Market Outlook
        </div>
    ),
    list121: [
        {
            label: "Potential Slowdown:",
            description:
                "Some experts predict a temporary slowdown in investment-driven markets like NCR and Mumbai due to the removal of indexation benefits.",
        },
        {
            label: "End-User-Driven Markets:",
            description:
                "Cities like Bangalore, Hyderabad, and Pune, driven mainly by end-user demand, are expected to be less affected.",
        },
        {
            label: "Price Fluctuations:",
            description:
                "While prices have surged recently, the long-term impact of these tax changes on property values remains to be seen.",
        },
    ],
    title13: (
        <div id="pmay-boost" className="scroll-top">
            PMAY (Pradhan Mantri Awas Yojana) Gets a Boost
        </div>
    ),
    description131:
        "The Pradhan Mantri Awas Yojana (PMAY) has been a cornerstone of the government's affordable housing initiative. Budget 2024 expanded the PMAY-Urban scheme with a massive investment of ₹10 lakh crore to address the housing needs of one crore urban households. This includes provisions for interest subsidies to make homeownership more accessible.",
    title14: (
        <div id="what-is-pmay" className="scroll-top">
            What is PMAY?
        </div>
    ),
    description141: (
        <div>
            Launched in 2015, the Pradhan Mantri Awas Yojana (PMAY) is a
            flagship program designed to ensure that everyone in India has
            access to safe and affordable housing. The initiative is split into
            two main components:{" "}
            <span className="font-benton-bold">
                Pradhan Mantri Awas Yojana-Gramin (PMAY-G)
            </span>{" "}
            for rural areas, and{" "}
            <span className="font-benton-bold">
                Pradhan Mantri Awas Yojana-Urban (PMAY-U)
            </span>{" "}
            for urban areas. Both parts focus on providing financial aid to
            eligible beneficiaries for constructing durable homes with essential
            amenities.
        </div>
    ),
    title15: (
        <div id="how-does-pmay-work" className="scroll-top">
            How does this work?
        </div>
    ),
    description151:
        "This initiative operates under a Credit Linked Subsidy Scheme (CLSS), aiming to make housing more accessible. Beneficiaries can receive a subsidy on the interest paid for home loans used to build, purchase, renovate, or extend their homes.",
    title16: (
        <div id="key-takeaway" className="scroll-top">
            Key Takeaway from Budget 2024
        </div>
    ),
    description161: (
        <div>
            Finance Minister Nirmala Sitharaman outlined{" "}
            <span className="font-benton-bold">nine</span> key priorities in the
            budget aimed at creating ample opportunities for everyone.These
            priorities include :
        </div>
    ),
    list162: [
        "Productivity and Resilience in Agriculture",
        "Employment & Skilling",
        "Inclusive Human Resource Development and Social Justice",
        "Manufacturing & Services",
        "Urban Development",
        "Energy Security",
        "Infrastructure",
        "Innovation, Research & Development",
        "Next Generation Reforms",
    ],
    description163:
        "These focus areas are designed to drive growth, enhance social equity, and foster innovation across various sectors.",
    title17: (
        <div id="summary" className="scroll-top">
            Summary
        </div>
    ),
    description171:
        "Overall, the Union Budget 2024 has introduced several positive measures for the real estate sector, including tax benefits, increased investment in affordable housing, and a focus on urban development. While some changes may have short-term implications, the long-term impact on the real estate market remains to be seen.",

    Faq: (
        <div id="faqs" className="scroll-top">
            FAQs (Frequently Asked Questions)
        </div>
    ),
    faqs: [
        // {
        //     label: "Q1: How will the removal of indexation benefit affect property prices ?",
        //     description: [
        //         "The impact on property prices is complex and depends on various factors, including location, property type, and market demand.",
        //     ],
        // },
        {
            label: "Q1: What are the key benefits of the reduced LTCG tax rate ?",
            description: [
                "The lower tax rate can make property investments more attractive and increase market activity.",
            ],
        },
        {
            label: "Q2: What is indexation, and how does it affect capital gains tax ?",
            description: [
                "Indexation adjusts the purchase price of an asset to account for inflation, reducing the taxable capital gain when you sell the asset. This helps protect your investment from the eroding effects of inflation.",
            ],
        },
        {
            label: "Q3: How has the holding period for assets changed in the 2024 Budget ?",
            description: [
                "The holding period for listed securities remains 12 months to be considered long-term. For other assets, the holding period is now 24 months. The previous 36-month holding period has been eliminated.",
            ],
        },
    ],
};

export default data;
