import { Card, CardDeck } from "react-bootstrap";
import "./blogs.scss";
// import img1 from "https://modernspaaces.com/BlogListImages/BlogOne/Banner.webp";
// import img2 from "https://modernspaaces.com/BlogListImages/BlogTwo/Banner.webp";
// import img3 from "https://modernspaaces.com/BlogListImages/BlogThree/Banner.webp";

// import img1 from "images/Blogs/MivanTechnology.jpeg";
// import img2 from "../../images/Blogs/reraInfluence.png";
// import img3 from "../../images/Blogs/EnergyEfficientBuilding.png";
// import ComingSoon from "../../components/Global/ComingSoon";
// import { useState } from "react";
import { Carousel } from "react-responsive-carousel";
import { Link } from "react-router-dom";

const img1 = "/images/Blogs/mivan-technology-banner.jpeg";
const img2 = "/images/Blogs/rera-act-influence-banner.png";
const img3 = "/images/Blogs/energy-efficient-building-banner.png";
const img4 =
    "/images/Blogs/benefits-of-living-in-an-exclusive-3-BHK-community.png";

export default function Blogs({ inView }) {
    // const [show, setShow] = useState(false);

    // const handleShow = (e) => {
    //   e.preventDefault();
    //   setShow(true);
    // };
    // const handleClose = () => setShow(false);
    const items = [
        {
            to: "/blogs/benefits-of-living-in-an-exclusive-3-BHK-community",
            name: "Benefits of living in an exclusive 3 BHK community",
            img: img4,
            alt: "",
            desc: "Discover Engrace Vista, an exclusive 3 BHK community off Sarjapur Road, Bangalore. Experience the best of urban living with spacious apartments, top-tier amenities, enhanced security, and a vibrant community atmosphere",
            date: "May,2024",
            time: "8 min read",
        },
        {
            to: "/blogs/exploring-the-mivan-technology",
            name: "Revolutionizing Construction: Exploring the Mivan Technology",
            img: img1,
            alt: "Mivan Technology - Aluform Technology",
            // img: "https://modernspaaces.com/BlogListImages/BlogTwo/Banner.webp",
            desc: "Exploring the technology of Mivan and its impact on construction. Learn more about the technology through a detailed analysis.",
            date: "January,2024",
            time: "5 min read",
        },
        {
            to: "/blogs/rera-how-it-benefits-homeowners-and-developers",
            name: "RERA's Influence | How does It Benefit the Homeowners and Developers?",
            img: img2,
            alt: "RERA benefits of homeowner and developers ",
            // img: "https://modernspaaces.com/BlogListImages/BlogTwo/Banner.webp",
            desc: "Discover how the RERA Act impacts home buyers and developers in India. Learn more about the basic principles and the impact it has upon home buyers and developers.",
            date: "January,2024",
            time: "4 min read",
        },
        // {
        //     to: "/blogs/choose-between-green-traditional-energy-efficient-buildings",
        //     name: "Choosing Between Green, Traditional, and Energy-Efficient Buildings in Real Estate",
        //     img: img3,
        //     alt: "Energy Efficent Buildings",
        //     // img: "https://modernspaaces.com/BlogListImages/BlogTwo/Banner.webp",
        //     desc: "Urbanization and the Changing Landscape The global wave of urbanization has profoundly reshaped landscapes, impacting natural resources and intensifying carbon emissions.",
        //     date: "Jan,2024",
        //     time: "5 min read",
        // },

        // {
        //   to: "/blogs/things-to-consider-buying-your-first-home",
        //   name: "Everything you need to know before buying your first home",
        //   img: img2,
        //   // img: "https://modernspaaces.com/BlogListImages/BlogTwo/Banner.webp",
        //   desc: "After years of hard work and saving up, you have finally decided to buy your first house more...",
        //   date: "Dec,2021",
        //   time: "3 min read",
        // },
        // {
        //   to: "/blogs/list-of-amenities-to-consider-buying-home",
        //   name: "A complete list of amenities you should look for before buying a",
        //   // img: "https://modernspaaces.com/BlogListImages/BlogOne/Banner.webp",
        //   img: img1,
        //   desc: "For any real estate project, its amenities are the most crucial part, as that’s what the builders use to lure potential..",
        //   date: "Dec,2021",
        //   time: "2 min read",
        // },

        // {
        //   to: "/blogs/buying-your-house-in-neighborhood",
        //   name: "Top benefits of buying your dream home in a gated community",
        //   // img: "https://modernspaaces.com/BlogListImages/BlogThree/Banner.webp",

        //   img: img3,
        //   desc: "A housing property or a residential community with a name and geographical demarcation set apart with gates.",
        //   date: "Jan,2021",
        //   time: "2 min read",
        // },
    ];

    return (
        <>
            <section
                className={`section section-blogs ${
                    inView ? "inView" : "outView"
                }
    `}
            >
                <div className="content h-100 ">
                    <div className="sub-heading m-auto fc-white">Blogs</div>

                    <CardDeck className="blogs noScroll pb-2 d-none d-sm-flex">
                        {items.map((item, idx) => {
                            return (
                                <Card
                                    key={idx}
                                    className="blog fc-white bg-dark-4"
                                >
                                    <Link className="text-white" to={item.to}>
                                        <div className="img-wrapper">
                                            <Card.Img
                                                variant="top"
                                                src={item.img}
                                                alt={item.alt}
                                            />
                                        </div>
                                        <Card.Body style={{ flex: "1 auto" }}>
                                            <Card.Title className="name  m-0">
                                                {item.name}
                                            </Card.Title>
                                            <Card.Text className="desc">
                                                {item.desc}
                                            </Card.Text>
                                        </Card.Body>
                                        <Card.Footer className="pb-3">
                                            <div className="separator"></div>
                                            <small className="date float-left">
                                                {item.date}
                                            </small>
                                            <small className="time float-right">
                                                {item.time}
                                            </small>
                                        </Card.Footer>
                                    </Link>
                                </Card>
                            );
                        })}
                    </CardDeck>
                    <Carousel
                        className="d-sm-none"
                        swipeable
                        showArrows={false}
                        showStatus={false}
                        interval={1e11}
                        centerMode
                        centerSlidePercentage={100}
                    >
                        {items.map((item, idx) => {
                            return (
                                <Card
                                    key={idx}
                                    className="blog fc-white bg-dark-4"
                                >
                                    <Link className="text-white" to={item.to}>
                                        <div className="img-wrapper">
                                            <Card.Img
                                                variant="top"
                                                src={item.img}
                                            />
                                        </div>
                                        <Card.Body style={{ flex: "1 auto" }}>
                                            <Card.Title className="name  m-0">
                                                {item.name}
                                            </Card.Title>
                                            <Card.Text className="desc">
                                                {item.desc}
                                            </Card.Text>
                                        </Card.Body>
                                        <Card.Footer className="pb-3">
                                            <div className="separator mb-3"></div>
                                            <small className="date float-left">
                                                {item.date}
                                            </small>
                                            <small className="time float-right">
                                                {item.time}
                                            </small>
                                        </Card.Footer>
                                    </Link>
                                </Card>
                            );
                        })}
                    </Carousel>

                    <div className="text-center btn-wrapper">
                        <a href="/blogs">
                            <button>
                                <span className="btn-text">View more</span>
                            </button>
                        </a>
                        {/* onClick={handleShow} */}
                        {/* <a className="app-btn--hollow--primary py-3" href="/blogs">
            <span className="app-btn-text">View more</span>
          </a> */}
                    </div>
                </div>

                {/* ComingSoon */}
                {/* <ComingSoon show={show} handleClose={handleClose} /> */}
            </section>
        </>
    );
}
