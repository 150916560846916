import { useState } from "react";
import { InView } from "react-intersection-observer";
// RelatedArticles

import Banner from "./Banner/index.js";
export default function BlogReadyMadeOrCustomVilla() {
    const [inViewBanner, setInViewBanner] = useState(false);
    // const [inViewRelatedArticles, setInViewRelatedArticles] = useState(false);
    const [
        inViewShareBlogReadyMadeOrCustomVilla,
        setInViewShareBlogReadyMadeOrCustomVilla,
    ] = useState(false);
    return (
        <>
            <InView
                className="bgPattern"
                onChange={setInViewShareBlogReadyMadeOrCustomVilla}
                triggerOnce={true}
            >
                {/* <ShareBlogReadyMadeOrCustomVilla
          shareUrl={
            "https://modernspaaces.com/blogs/defference-between-a-khata-b-khata-and-its-impact-on-property"
          }
          inView={inViewShareBlogReadyMadeOrCustomVilla}
        ></ShareBlogReadyMadeOrCustomVilla> */}
            </InView>
            <InView className="" onChange={setInViewBanner} triggerOnce={true}>
                <Banner inView={inViewBanner}></Banner>
            </InView>
        </>
    );
}
