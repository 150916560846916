const banner = "/images/Blogs/BlogApartmentVsHouse.png";
const loanApproval = "/images/Blogs/LoanApproval.png";

const data = {
    slug: "buying-apartment-vs-building-house",
    image: banner,
    imageAlt:
        "Are you still over the fence between Buying an Apartment and Building a House?",
    title: "Are you still over the fence between Buying an Apartment and Building a House?",
    time: "7 min read",
    publishedOn: "October,2024",
    title1: "Table of Contents",
    description11: (
        <ul className="table-of-content">
            <li>
                <a
                    href="#introduction"
                    onClick={() => {
                        window.scrollToElement("#introduction");
                    }}
                >
                    Introduction
                </a>
            </li>
            <li>
                <a
                    href="#choosing-a-site"
                    onClick={() => {
                        window.scrollToElement("#choosing-a-site");
                    }}
                >
                    Choosing a Site
                </a>
            </li>
            <li>
                <a
                    href="#approval-from-bbmp-and-bmrda"
                    onClick={() => {
                        window.scrollToElement("#approval-from-bbmp-and-bmrda");
                    }}
                >
                    Approval from BBMP and BMRDA
                </a>
            </li>
            <li>
                <a
                    href="#average-cost"
                    onClick={() => {
                        window.scrollToElement("#average-cost");
                    }}
                >
                    Average Cost
                </a>
            </li>
            <li>
                <a
                    href="#time-and-effort"
                    onClick={() => {
                        window.scrollToElement("#time-and-effort");
                    }}
                >
                    Time and Effort
                </a>
            </li>
            <li>
                <a
                    href="#customisation-vs-convenience"
                    onClick={() => {
                        window.scrollToElement("#customisation-vs-convenience");
                    }}
                >
                    Customisation v/s Convenience
                </a>
            </li>
            <li>
                <a
                    href="#wait-time"
                    onClick={() => {
                        window.scrollToElement("#wait-time");
                    }}
                >
                    Wait Time
                </a>
            </li>

            <li>
                <a
                    href="#stress-and-attention-to-detail"
                    onClick={() => {
                        window.scrollToElement(
                            "#stress-and-attention-to-detail"
                        );
                    }}
                >
                    Stress and Attention to Detail
                </a>
            </li>
            <li>
                <a
                    href="#budget-oversight"
                    onClick={() => {
                        window.scrollToElement("#budget-oversight");
                    }}
                >
                    Budget Oversight
                </a>
            </li>
            <li>
                <a
                    href="#warranty-period"
                    onClick={() => {
                        window.scrollToElement("#warranty-period");
                    }}
                >
                    Warranty Period
                </a>
            </li>
            <li>
                <a
                    href="#future-potential-and-resale-value"
                    onClick={() => {
                        window.scrollToElement(
                            "#future-potential-and-resale-value"
                        );
                    }}
                >
                    Future Potential and Resale Value
                </a>
            </li>
            <li>
                <a
                    href="#loan-approval"
                    onClick={() => {
                        window.scrollToElement("#loan-approval");
                    }}
                >
                    Loan Approval
                </a>
            </li>
            <li>
                <a
                    href="#additional-perks"
                    onClick={() => {
                        window.scrollToElement("#additional-perks");
                    }}
                >
                    Additional Perks
                </a>
            </li>
            <li>
                <a
                    href="#reconstruction"
                    onClick={() => {
                        window.scrollToElement("#reconstruction");
                    }}
                >
                    Reconstruction
                </a>
            </li>
            <li>
                <a
                    href="#final-insights"
                    onClick={() => {
                        window.scrollToElement("#final-insights");
                    }}
                >
                    Final Insights
                </a>
            </li>
        </ul>
    ),
    title2: (
        <div id="introduction" className="scroll-top">
            Introduction
        </div>
    ),
    description21:
        "Turning your dream home into reality is an exciting journey, but the decision between building an independent home and buying an apartment can be daunting. Both options come with unique advantages and challenges, and your choice will significantly impact your lifestyle, financial planning, and future prospects. In this blog, we'll delve into the critical factors you need to consider, from location and cost to customisation and resale value, to help you make an informed decision that aligns with your vision and circumstances.",
    title3: (
        <div id="choosing-a-site" className="scroll-top">
            1. Choosing a site
        </div>
    ),
    list31: [
        {
            label: "Building a house",
            description:
                "The first step for building a house is to secure a plot and while doing so it is important to conduct a thorough background check on the plot and the seller to ensure it's free of legal disputes and has clear ownership titles. Before finalising the purchase, you should hire a licensed civil engineer to assess the soil conditions on the plot. This helps to ensure the structural integrity and long-term stability of the constructed house.",
        },
        {
            label: "Buying an apartment",
            description:
                "When buying an apartment, reputable builders like Modern Spaaces develop their projects in the most sought-after locations in a great neighbourhood. They develop their apartments in highly desirable locations, so you can be confident you're choosing a vibrant neighbourhood with a high quality of life. Their extensive research ensures you get the benefits of a well-planned community, something that can be hard to find on your own.",
        },
    ],
    title4: (
        <div id="approval-from-bbmp-and-bmrda" className="scroll-top">
            2. Approval from BBMP and BMRDA
        </div>
    ),
    list41: [
        {
            label: "Building a house",
            description: (
                <>
                    Building a home can be a complex process, especially when it
                    comes to securing permits and financing. Obtaining approval
                    requires a significant amount of paperwork, including
                    property documents like the Mother deed, sale deed, Khata,
                    and tax receipts. This can be time-consuming due to the
                    bureaucracy involved. Managing the various documents and
                    following up with different departments can be a major
                    hassle.<br></br> It is important to get your plan approved
                    by the BBMP or BMRDA authorities. Therefore, reserve 10% of
                    the cost for all kinds of bureaucratic processes and
                    obtaining approvals for electrical and sanitary works. This
                    is an additional cost to factor into your budget.
                </>
            ),
        },
        {
            label: "Buying an apartment",
            description:
                "Acquiring an apartment from a well-established builder typically involves minimal bureaucratic processes, with the primary requirements being payment of registration charges and stamp duty. However, it is still important to verify all the relevant documents and contracts before going ahead with the deal.",
        },
    ],
    title5: (
        <div id="average-cost" className="scroll-top">
            3. Average Cost
        </div>
    ),
    list51: [
        {
            label: "Building a house",
            description:
                "In Bangalore's booming real estate market, the high demand for residential development significantly impacts construction costs, often reaching higher figures. These costs can vary widely depending on the city and factors such as labour availability, raw material supply, and the quality of furnishings used. You should factor in civil charges and finishing costs, which include labour for electricians, plumbers, tile masons, carpenters, painters, and polishers. Remember, the total construction cost of a house encompasses both civil work and finishing expenses.",
        },
        {
            label: "Buying an apartment",
            description:
                "Building an apartment complex allows builders/developers to employ new and innovative construction methods and leverage economies of scale. This means they can purchase materials in bulk at a discounted rate, reducing the overall construction cost per apartment. This translates to a more affordable price for you compared to building a custom home.",
        },
    ],
    list52: [
        {
            label: "Example",
            description: (
                <>
                    Modern Spaaces employs{" "}
                    <a
                        href="https://modernspaaces.com/blogs/exploring-the-mivan-technology"
                        className="external-link"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Aluminium Formwork technology
                    </a>
                    , which offers numerous advantages such as faster
                    construction, exceptional quality, and significant cost
                    savings. This is a win-win for homebuyers because they get
                    homes built with the latest technology and benefit from
                    timely project completion, allowing them to move in quickly.
                </>
            ),
        },
    ],

    title6: (
        <div id="time-and-effort" className="scroll-top">
            4. Time and Effort
        </div>
    ),
    list61: [
        {
            label: "Building a house",
            description:
                "For working professionals, time is a precious resource, so spending time equates to spending money. Building a home requires time and effort, including securing permits, working with architects and builders, and overseeing the construction process.",
        },
        {
            label: "Buying an apartment",
            description:
                "While searching for apartments might require time, the key difference lies in effort and involvement in the building process. Apartments let you skip the complexities of construction management and require minimal involvement during the construction phase. If you're not keen on waiting, a ready-to-move-in apartment is the best option for you. Consulting an expert like a realtor can further simplify the process.",
        },
    ],
    title7: (
        <div id="customisation-vs-convenience" className="scroll-top">
            5. Customisation v/s Convenience
        </div>
    ),
    list71: [
        {
            label: "Building a house",
            description:
                "Building a home gives you complete control over the design and layout, allowing you to create your home according to your preferences and needs. This means you can tailor every aspect of the home to suit your lifestyle, from the architectural style to the interior finishes, ensuring a truly personalised living experience.",
        },
        {
            label: "Buying an apartment",
            description:
                "Apartments are part of a larger building structure, so customisation like altering walls, knocking them down, or extending any room is usually a no-go because of safety and structural concerns. Think of it like using a pre-designed template; you can't change the fundamental layout, but you can definitely get creative and personalise it within the existing framework.",
        },
    ],
    title8: (
        <div id="wait-time" className="scroll-top">
            6. Wait Time
        </div>
    ),
    list81: [
        {
            label: "Building a house",
            description:
                "Constructing a new house typically takes around a year, not including the planning and approval phases. During this construction period, you'll need to cover living expenses, which could mean paying rent or your current home loan alongside the construction costs, leading to financial strain. ",
        },
        {
            label: "Buying an apartment",
            description:
                "Opting for an under-construction apartment means you'll need to wait until the building process is completed, which can take somewhere around a year depending on the size of the project. However, newer technologies like Aluminum Formwork can considerably speed up construction compared to traditional methods. On the other hand, investing in a ready-to-move-in apartment eliminates the waiting period entirely, allowing you to move in immediately.",
        },
    ],
    title9: (
        <div id="stress-and-attention-to-detail" className="scroll-top">
            7. Stress and Attention to Detail
        </div>
    ),
    list91: [
        {
            label: "Building a house",
            description: (
                <>
                    Building your home puts you in charge of countless
                    decisions, from purchasing the land to selecting home
                    designs, flooring, fixtures, cabinets, countertops, and
                    more, all while staying within your budget. Managing these
                    numerous details and dealing with growing expenses demands
                    considerable time and effort.<br></br> When hiring a
                    contractor, it's crucial to consider their experience and
                    qualifications. Be aware that some contractors might lack
                    the necessary permits or may not be entirely transparent,
                    which could affect both your project's timeline and costs.
                    To ensure your project meets your expectations, focus on
                    thorough planning and open communication.
                </>
            ),
        },
        {
            label: "Buying an apartment",
            description:
                "On the other hand, buying an apartment ensures that all the stress and planning are efficiently handled by the builders. Also, the apartments are built to high standards of quality and safety. Reputed builders often collaborate with experienced architects to create well-designed spaces that prioritise functionality, aesthetics, and durability. This careful planning and execution result in apartments that meet industry standards.",
        },
    ],
    title10: (
        <div id="budget-oversight" className="scroll-top">
            8. Budget Oversight
        </div>
    ),
    list101: [
        {
            label: "Building a house",
            description: (
                <>
                    The excitement of building your dream home can get you
                    carried away and you might overspend. On top of that
                    inflation has made everything costlier. For instance, labour
                    costs have increased greatly in recent years, so you need to
                    budget for both materials and labour. There can be
                    unexpected costs involved in building a home and it's easy
                    to get caught up in the allure of customisations and
                    high-end finishes, which can significantly increase the
                    budget.<br></br> For example, disposing of the rubbish
                    generated during construction can add a significant, often
                    unforeseen cost to the project. So, it's important to be
                    careful and keep a close eye on your spending to avoid that
                    post-construction shock.
                </>
            ),
        },
        {
            label: "Buying an apartment",
            description:
                "Buying an apartment typically involves establishing a fixed budget as the initial step. This helps homebuyers have a clear understanding of their capabilities, so it is easier for them to understand the financial commitment. Any cost increases are managed by the builder, and due to the larger scale of construction, the average construction cost per apartment is significantly lower.",
        },
    ],
    title11: (
        <div id="warranty-period" className="scroll-top">
            9. Warranty Period
        </div>
    ),
    list111: [
        {
            label: "Building a house",
            description:
                "When building your home, homeowners typically handle any future problems that arise, as contractors are only involved during the construction phase. Therefore, it's crucial to choose a reputable contractor to ensure the project unfolds smoothly and meets your expectations. Unlike some contractors who cut corners and leave you with future repair headaches, a quality contractor prioritises high-grade materials and skilled workers.  This means you can relax, knowing your investment is safe from issues like leaky roofs, uneven floors, or structural problems.",
        },
        {
            label: "Buying an apartment",
            description: (
                <>
                    Buying a RERA-certified apartment means that the home comes
                    with a warranty known as the Defect Liability, also known as
                    the builder warranty period, which starts after the project
                    is finished and handed over to the homeowner.<br></br> The
                    builder is responsible for fixing only structural defects
                    without charging the homeowner extra during this period.
                    According to the Real Estate Regulation Act (RERA), this
                    period is set at 12 to 24 months from the date of
                    possession.
                </>
            ),
        },
    ],
    title12: (
        <div id="future-potential-and-resale-value" className="scroll-top">
            10. Future Potential and Resale Value
        </div>
    ),
    list121: [
        {
            label: "Building a house",
            description:
                "Owning an independent home can be a smart long-term investment. Land value tends to rise steadily, offering potential returns on your investment as the years go by. However, it's important to remember that houses, unlike land, depreciate (lose value) over time due to wear and tear. To maximise your return on investment, consistent upkeep of the structure is necessary.",
        },
        {
            label: "Buying an apartment",
            description: (
                <>
                    When it comes to resale value, apartments might not always
                    hold the top spot compared to standalone homes. However, in
                    densely populated metro cities with limited space,
                    apartments become the go-to option for urban living and
                    convenience, leading to high resale potential.<br></br> Take
                    South East Bangalore ( Sarjapur road and adjoining area) for
                    instance, where apartments reign supreme, making up 80% of
                    available properties. Here, factors like a thriving job
                    market, proximity to international schools, and
                    infrastructure development like new metro lines or shopping
                    malls have significantly impacted resale value.
                </>
            ),
        },
    ],
    image131: loanApproval,
    imageAlt131:
        "Are you still over the fence between Buying an Apartment and Building a House?",
    title131: (
        <div id="loan-approval" className="scroll-top">
            11. Loan Approval
        </div>
    ),
    list132: [
        {
            label: "Building a house",
            description: (
                <>
                    Financing the building process can be done through a
                    construction loan or land loan, but the approval process is
                    more complex than that for an apartment loan. Banks require
                    detailed plans, construction budget estimates, and proof of
                    ownership of the land. Additionally, for construction loans,
                    lenders disburse the loan amount in instalments based on
                    construction progress.<br></br> Extra documentation such as
                    approval from the Municipal Corporation and an
                    architect-authorised construction blueprint is needed, along
                    with the standard documents required for home loans.
                    Construction loan interest rates are also higher than home
                    loan rates due to their limited availability and less
                    frequent use.
                </>
            ),
        },
        {
            label: "Buying an apartment",
            description:
                "Compared to construction loans, the process is simpler and faster. Banks typically have fewer requirements for approval, resulting in quicker turnaround times. Interest rates are also lower for home loans, making them a more budget-friendly option. Some builders may even have tie-ups with banks to streamline the paperwork or offer pre-approved loan options, although approval will still rely on the criteria set by the bank. Finally, choosing a RERA-verified property from a reputable builder simplifies the process further as it assures legal compliance for the bank.",
        },
    ],
    title14: (
        <div id="additional-perks" className="scroll-top">
            12. Additional perks
        </div>
    ),
    list141: [
        {
            label: "Building a house",
            description:
                "Independent homes offer more privacy than apartments, allowing you to enjoy personal space. This means you can create a living environment tailored to your preferences without worrying about noise or disturbances from adjacent units. However, homeowners are solely responsible for their security, which means they need to install CCTV cameras, and smart locks, and hire security guards at their own expense. Additionally, maintaining the property, including landscaping, repairs, and general upkeep, falls entirely on the homeowner, requiring more time and financial investment compared to apartment living.",
        },
        {
            label: "Buying an apartment",
            description:
                "Apartments often come with attractive perks beyond just the living space. Secured gated communities with features like round-the-clock security features offer peace of mind. Many complexes boast amenities like swimming pools, gyms, clubhouses, landscaped gardens, and maintained common areas, providing residents with a resort-style living experience. Additionally, a dedicated management committee handles maintenance, ensuring a hassle-free lifestyle.",
        },
    ],
    title15: (
        <div id="reconstruction" className="scroll-top">
            13. Reconstruction
        </div>
    ),
    list151: [
        {
            label: "Building a house",
            description:
                "Reconstructing a standalone home offers a great deal of flexibility. Whether you're planning to demolish and rebuild, add an extra room or even new floors, it's entirely possible as long as you secure the necessary permissions from the relevant authorities.",
        },
        {
            label: "Buying an apartment",
            description:
                "Unlike a house, an apartment comes with its own set of rules and restrictions. Since you share the space with many other residents, there are strict regulations to consider. Major changes, like altering load-bearing walls, adding an extra room, or modifying the exterior, are off-limits. However, you can make some minor adjustments within your own apartment.",
        },
    ],
    title16: (
        <div id="final-insights" className="scroll-top">
            Final Insights
        </div>
    ),
    description161:
        "Ultimately, the decision hinges on your priorities, as choosing a place to call home is deeply personal. Assess your financial situation and homeownership goals carefully. Each option involves trade-offs, whether it's in terms of customisation or specific features of the house. Once you've pinpointed what best suits your needs, you can confidently make your choice.",
    description162: (
        <>
            At <span className="font-benton-bold">Modern Spaaces</span>, we
            meticulously design apartments that maximise space and convenience,
            catering to the needs of today's urban dwellers. Location is
            everything, which is why we strategically select prime areas with
            easy access to everything you need. Imagine being steps away from IT
            parks, top schools, planned developments like metro lines, improved
            roads, and bustling commercial hubs.
        </>
    ),

    table163header: ["Aspect", "Building a House", "Buying an Apartment"],
    table163: [
        {
            col1: "Approval from Authorities",
            col2: "Complex, time-consuming",
            col3: "Relatively simple",
        },
        {
            col1: "Time and Effort",
            col2: "Requires significant time",
            col3: "Quicker and more straightforward",
        },
        {
            col1: "Stress Level",
            col2: "Requires managing numerous details and decisions by the owner",
            col3: "Builders are responsible for all the hassles",
        },
        {
            col1: "Budget Oversight",
            col2: "High risk of overspending",
            col3: "Fixed budget; any cost increases are managed by the builder",
        },
        {
            col1: "Warranty Period",
            col2: "None",
            col3: "RERA-certified apartments come with a 5-year warranty for structural defects post-possession",
        },
        {
            col1: "Loan Approval",
            col2: "More complex approval process for construction loans.",
            col3: "Easier loan approval process for apartments",
        },
        {
            col1: "Customisation",
            col2: "High",
            col3: "Limited Scope",
        },
    ],

    description164: (
        <>
            But it's not just about convenience. At Modern Spaaces, quality is
            paramount. We use high-quality materials and construction practices
            to ensure our apartments and Villas are well-maintained and retain
            their value for years to come. Contemporary amenities like sparkling
            pools, expansive gyms, and vibrant community spaces go beyond simple
            living; they elevate your lifestyle.<br></br>{" "}
            <b className="font-benton-bold">
                Ready to experience the Modern Spaaces difference?
            </b>{" "}
            Visit{" "}
            <a
                href="https://modernspaaces.com"
                className="external-link"
                target="_blank"
                rel="noreferrer"
            >
                www.modernspaaces.com{" "}
            </a>
            to find your dream home today.
        </>
    ),

    Faq: (
        <div id="faqs" className="scroll-top">
            FAQs (Frequently Asked Questions)
        </div>
    ),
    faqs: [
        {
            label: "Q1: Which is cheaper: Building a home or buying an apartment?",
            description: [
                "Generally, buying an apartment is the more affordable option upfront due to economies of scale in construction. However, building a house can increase in value over time if the land value increases.",
            ],
        },
        {
            label: "Q2: “I want a lot of customisation”- What should I choose?",
            description: [
                "Building a house offers complete control over the design and layout, allowing for maximum customisation. Apartments typically have limited opportunities for structural changes.",
            ],
        },
        {
            label: "Q3: “I don't want to deal with construction”- Is an apartment better?",
            description: [
                "Yes, Purchasing an apartment means you can skip the construction headaches. The builder takes care of all the details, letting you enjoy a move-in-ready home without the stress.",
            ],
        },
    ],
};

export default data;
